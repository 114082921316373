export function getUserWithExpiry() {
    const itemStr = localStorage.getItem('user');
    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();
    
    if (now.getTime() > item.expiry) {
        localStorage.removeItem('user');
        window.location.href = '/dashboard';
        return null;
    }

    return item;
}