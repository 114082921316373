import React from 'react';
import { react } from "@babel/types";
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory
import { useAuth } from '../../context/AuthContext';
import { getUserWithExpiry } from '../../services/authUtils';
import { MdLogout } from "react-icons/md";

import logo from "../../assets/logo-white.svg";

function NavBar() {
  const { user, logout } = useAuth();

  return (
    <div className="bg-amco-gold">
      <div className="grid grid-cols-2 max-w-screen-xl center-screen">
        <div className="navBar p-10 ">
          <a href="https://www.amco.pt" target="_blank" alt="AMCO Intermediários de Crédito">
            <img
              className="h-12 w-auto logo-amco-nav align-middle"
              src={logo}
              alt="AMCO | Intermediários de Crédito"
            />
          </a>
        </div>
        <div className="flex items-center justify-end h-full">
          <ul className="md:flex sm:block">
            <li className="rounded-full bg-white/15  text-center p-2 mr-[20px] sm:w-40 md:w-60">
              <a href="/dashboard">
                <span className="font-semibold text-sm text-white">Estado do Processo</span>
              </a>
            </li>

            {(user) && (
              <li
                className="w-auto md:text-center p-2 px-8 sm:text-right">
                <span className="flex font-semibold text-sm text-white">
                  <MdLogout className="text-white size-6" onClick={logout} />
                  Sair
                </span>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
