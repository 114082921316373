import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/authService';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const sessionToken = localStorage.getItem('sessionToken');
    const input = localStorage.getItem('input');

    if (sessionToken && input) {
      setUser({ input, sessionToken });
    }
  }, []);

  const requestOtp = async (input) => {
    return AuthService.requestOtp(input);
  };

  const verifyOtp = async (input, otp, accessToken) => {
    return AuthService.verifyOtp(input, otp, accessToken);
  };

  const logout = () => {
    AuthService.logout();
    setUser(null);
    navigate('/login');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated: !!user, user, requestOtp, verifyOtp, logout }}>
        {children}
    </AuthContext.Provider>
  );
};
