import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { getUserWithExpiry } from '../../services/authUtils';

import apiService from '../../services/apiService';
import NavBar from '../Common/NavBar';
import CopyrightInfo from '../Common/CopyrightInfo';
import ContactBanner from '../Common/ContactBanner';
import ProcessCard from '../Process/ProcessCard';
import PwaInstallPrompt from '../Common/PWAInstallPrompt';

const Dashboard = () => {
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [processData, setProcessData] = useState([]);

    useEffect(() => {
        const fetchProcessData = async () => {
            const user = getUserWithExpiry();
            if (!user) {
                navigate('/login');
                return;
            }
            setLoading(true);
            try {
                const initialData = await apiService.getProcessID(user.input, user.accessToken);
                if (initialData && initialData.proc_processes && initialData.proc_processes.length > 0) {
                    const processId = initialData.proc_processes[0].id;
                    const detailedProcessData = await apiService.fetchProcessDetails(processId);
                    localStorage.setItem('processId', processId);
                    setProcessData(detailedProcessData);
                } else {
                    setError('No process data found.');
                }
            } catch (error) {
                console.error('Error fetching process data:', error);
                setError('Failed to load process data.');
                navigate('/login');
            } finally {
                setLoading(false);
            }
        };
        fetchProcessData();
    }, [navigate]);

    const getGreeting = () => {
        const hour = new Date().getHours();
        if (hour < 12) return "Bom dia";
        if (hour < 20) return "Boa tarde";
        return "Boa noite";
    };

    return (
        <div>
            <NavBar />
            <PwaInstallPrompt />
            <div className="md:flex sm:block justify-center bg-amco-gold container-noNav">
                <div className="soft-bg roundContainer p-10 ml-5 mr-5 max-w-screen-xl md:w-full">
                    <h2 className="text-xl font-bold mobile-spacing">{getGreeting()}, {processData.nameClient}</h2>
                    <p className="pb-8">Nesta área pode aceder a todos os seus processos ou iniciar um novo com apenas um clique.</p>
                    <hr />
                    {loading ? <p>Loading...</p> : <ProcessCard data={processData} />}
                    {error && <p className="text-red-500">{error}</p>}
                    <ContactBanner />
                    <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-0 mt-12">
                        <CopyrightInfo />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
