import axios from 'axios';
import { API_URL } from '../config/configs';

const AuthService = {
  // Function to initiate OTP request
  requestOtp: async (input) => {
    try {
      const response = await axios.post(`${API_URL}/auth/simpleLogin`, { input });
      localStorage.setItem('input', input);
      localStorage.setItem('accessToken', response.data.accessToken);
      return response.data;
    } catch (error) {
      console.error('Request OTP error:', error);
      throw error;
    }
  },

  // Function to verify OTP
  verifyOtp: async (input, otp, accessToken) => {
    try {
      const response = await axios.post(`${API_URL}/auth/verifyOTP`, {
        input,
        otp,
        accessToken,
      });

      if (response.data.sessionToken) {
        const now = new Date();
        const item = {
          accessToken,
          input,
          sessionToken: response.data.sessionToken,
          expiry: now.getTime() + (60 * 60 * 1000),
        };
        localStorage.setItem('user', JSON.stringify(item));
      }

      return response.data;
    } catch (error) {
      console.error('Verify OTP error:', error);
      throw error;
    }
  },

  logout: () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('authToken');
    localStorage.removeItem('currentTab');
    localStorage.removeItem('input');
    localStorage.removeItem('processId');
    localStorage.removeItem('sessionToken');
    localStorage.removeItem('user');
  },

  getCurrentUser: () => {
    return JSON.parse(localStorage.getItem('user'));
  },
};

export default AuthService;
