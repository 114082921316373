import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { MdOutlineLock, MdOutlinePersonAddAlt, MdOutlineExpandMore, MdCheckCircle, MdWarning } from "react-icons/md";
import { RxCrossCircled } from "react-icons/rx";

const StepThree = ({ formData, processData, apiData, getCreditTypeDescriptionById, onlySimulation, preApproval, approvalStatus, addSecondHolder }) => {

    if (!apiData || !formData) {
        return <p>Estamos a calcular a viabilidade do seu pedido de crédito. Aguarde por favor...</p>;
    }

    // Recusa por taxa de esforço elevada
    // Recomendação: se clients === 1, adicionar segundo titular ou sugerir um cartão de crédito

    if (apiData && processData.effortRate > 50 && processData.clients === 1) {
        console.log(processData);
        return (
            <div className="space-y-4">
                <MdWarning className="w-[100px] h-[auto] text-orange-600 text-center ml-[auto] mr-[auto]" />
                <p className="font-bold text-xl text-orange-600 text-center">O seu processo foi recusado!</p>

                <p className="text-center md:pl-20 md:pr-20 sm:pl-4 sm:pr-4">
                    O processo de <span className="font-semibold">{getCreditTypeDescriptionById(processData.creditType)}</span> solicitado, no valor de <span className="font-semibold">{processData.amount}€</span>, para o prazo de <span className="font-semibold">{processData.deadline} meses</span> excede a sua taxa de esforço.
                    <span className="font-semibold"> Considere adicionar um segundo titular ao seu pedido para que o mesmo seja reavaliado.</span><br /><br />
                    <span>Alternativamente poderá solicitar um <span className="font-semibold">Cartão de Crédito</span> no valor máximo de 2.500€ <span className="font-semibold"><a href="#">aqui</a></span>.</span>
                </p>

                <div className="mt-20 flex space-between form-group col-12 px-0 d-block">
                    <button type="button" onClick={addSecondHolder} className="ml-2 w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-600  hover:bg-orange-700  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                        Adicionar Segundo Titular
                    </button>
                </div>
                <hr />
            </div>
        );
    }

    // Recusa por taxa de esforço elevada com 2 titulares
    // Recomendação: entrar em contacto direto para avaliar a melhor alternativa

    else if (apiData && processData.effortRate > 50 && process.clients === 2) {
        return (
            <div className="space-y-4">
                <RxCrossCircled className="w-[100px] h-[auto] text-red-600 text-center ml-[auto] mr-[auto]" />
                <p className="font-bold text-xl text-red-600 text-center">O seu processo foi recusado!</p>

                <p className="text-center md:pl-20 md:pr-20 sm:pl-4 sm:pr-4">
                    O processo de <span className="font-semibold">{getCreditTypeDescriptionById(processData.creditType)}</span> solicitado, no valor de <span className="font-semibold">{processData.amount}€</span>, para o prazo de <span className="font-semibold">{processData.deadline} meses</span> foi recusado porque excede a sua taxa de esforço. Para saber como poderá reduzir a sua taxa de esforço entre em contacto connosco através de +351 910 910 910.
                </p>

                <div className="mt-20 flex space-between form-group col-12 px-0 d-block">
                    <button type="button" onClick={onlySimulation} className="mr-2 w-full py-2 px-4 border border-dark rounded-md shadow-sm text-sm font-medium text-dark bg-transparent hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                        Voltar ao website da AMCO Intermediários de Crédito
                    </button>
                </div>
                <hr />
            </div>
        );
    }

    else {
        //Processo dentro de regras: APROVADO
        return (
            <div className="space-y-4">
                <MdCheckCircle className="w-[100px] h-[auto] text-green-600 text-center ml-[auto] mr-[auto]" />
                <p className="font-bold text-xl text-green-600 text-center">O seu processo foi pré-aprovado!</p>

                <p className="text-center md:pl-20 md:pr-20 sm:pl-4 sm:pr-4">
                    O processo de <span className="font-semibold">{getCreditTypeDescriptionById(processData.creditType)}</span> solicitado, no valor de <span className="font-semibold">{processData.amount}€</span>, para o prazo de <span className="font-semibold">{processData.deadline} meses</span> cumpre todos os requisitos de pré-aprovação.
                    A sua prestação mensal tem um valor estimado de:
                </p>

                <p className="text-center text-5xl">{processData.startRenderAux}€*</p>

                <div className="disclaimer text-xs text-center">*Valor meramente indicativo e corresponde a um valor médio de simulação</div>

                <div className="mt-20 md:flex sm:block space-between form-group col-12 px-0 d-block">
                    <button type="button" onClick={onlySimulation} className="mobile-spacing md:mr-2 w-full py-2 px-4 border border-dark rounded-md shadow-sm text-sm font-medium text-dark bg-transparent hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                        Apenas queria a simulação
                    </button>
                    <button type="button" onClick={preApproval} className="md:ml-2 w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                        Avançar
                    </button>
                </div>
                <hr />
            </div>
        );
    }
};

StepThree.propTypes = {
    formData: PropTypes.object.isRequired,
    processData: PropTypes.object.isRequired,
    apiData: PropTypes.shape({
        creditType: PropTypes.number,
        amount: PropTypes.number,
        deadline: PropTypes.number
    }).isRequired,
    nextStep: PropTypes.func.isRequired,
    getCreditTypeDescriptionById: PropTypes.func.isRequired,
    onlySimulation: PropTypes.func.isRequired,
    preApproval: PropTypes.func.isRequired
};

export default StepThree;