import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RedirectPage = ({ to, leadToken }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // const to = sessionStorage.getItem('redirectTarget') || '/';
    const timer = setTimeout(() => {
      navigate(to, { state: { leadToken } });
    }, 2000);

    return () => clearTimeout(timer);
  }, [navigate, to, leadToken]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <svg width="50" height="50" viewBox="0 0 50 50">
        <circle
          cx="25"
          cy="25"
          r="20"
          stroke="#FFD700"
          strokeWidth="4"
          fill="none"
          strokeLinecap="round"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 25 25"
            to="360 25 25"
            dur="1s"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
      <p>A redireccionar em 2 segundos...</p>
    </div>
  );
};

export default RedirectPage;
