import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext'; // Import useAuth hook
import authService from '../../services/authService'; // Ensure the path is correct
import { MdOutlineArrowBack } from "react-icons/md";

import PrivateRoute from '../../components/PrivateRoute';
import NavBar from '../Common/NavBar';
import BancoPortugalInfo from '../Common/BancoPortugalInfo';
import CopyrightInfo from '../Common/CopyrightInfo';

const RecoverPassword = () => {
    const [username, setUsername] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const auth = useAuth();

    const handleLogin = async (e) => {
        e.preventDefault();
        setError('');
        setSuccessMessage('');
        setLoading(true);
    
        try {
            const response = await authService.recoverPassword(username);

            if (response.status === 200) {
                setSuccessMessage(response.data.message);
            } else {
                setError('Password recovery failed. Please try again.');
            }
        } catch (error) {
            setError('Password recovery failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <NavBar />
            <div className="flex justify-center bg-amco-gold container-noNav">
                <div className="w-full soft-bg roundContainer p-10 ml-5 mr-5 max-w-screen-xl">
                    <h2 className="pb-8 text-xl font-bold">
                        Recuperar Password
                    </h2>

                    <form onSubmit={handleLogin}>
                        <label htmlFor="email" className='mb-[10px] block text-sm font-medium text-dark'>
                            Endereço de Email
                        </label>
                        <input
                            required
                            name="email" id="email"
                            type='text'
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder='Introduza o seu endereço de email'
                            className='mb-4 text-sm w-full bg-transparent rounded-md border border-stroke dark:border-dark-3 py-[10px] px-5 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
                        />

                        <div className="grid grid-cols-1">
                            <button
                                id="recoverPassword"
                                type="submit"
                                className={`mb-10 bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                disabled={loading}
                            >
                                {loading ? 'A processar...' : 'Recuperar'}
                            </button>
                            
                            {error && <p className="text-red-500 text-center mb-10">{error}</p>}
                            {successMessage && <p className="text-green-500 text-center mb-10">{successMessage}</p>}

                            <div className="flex flex-grow justify-center">
                                <MdOutlineArrowBack className="mr-2" />
                                <a href="/login" className="mb-4 text-center text-dark text-xs">Voltar ao início de sessão</a>
                            </div>
                        </div>
                    </form>

                    

                    <div className="grid grid-cols-5 gap-4 mt-12">
                        <CopyrightInfo />
                        <BancoPortugalInfo />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecoverPassword;
