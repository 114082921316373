import React, { useEffect, useState } from 'react';
import support from "../../assets/support.jpeg";

const PwaInstallPrompt = () => {
    const [promptEvent, setPromptEvent] = useState(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleBeforeInstallPrompt = (e) => {
            console.log('beforeinstallprompt event fired');
            const isActionTaken = document.cookie.split('; ').find(row => row.startsWith('pwaInstallPrompt='));
            if (isActionTaken) return;
            e.preventDefault();
            setPromptEvent(e);
            setIsVisible(true);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        return () => window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    }, []);

    const handleInstallClick = () => {
        if (!promptEvent) return;

        promptEvent.prompt();
        promptEvent.userChoice.then((choiceResult) => {
            console.log('User choice result:', choiceResult.outcome);
            if (choiceResult.outcome === 'accepted') {
                console.log('User accepted the install prompt');
            } else {
                console.log('User dismissed the install prompt');
            }
            setPromptEvent(null);
            setIsVisible(false);
        });
    };

    const handleClose = () => {
        setIsVisible(false);
        const date = new Date();
        date.setTime(date.getTime() + (24 * 60 * 60 * 1000)); // 24 hours from now
        const expires = "; expires=" + date.toUTCString();
        document.cookie = "pwaInstallPrompt=handled" + expires + "; path=/";
    };

    if (!isVisible) return null;

    return (
        <div className="fixed z-10 inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-5 rounded-lg shadow-lg w-[650px]">
                <div className="grid grid-cols-2 p-5">
                    <div className="col-span-1">
                        <img
                            className="h-[170px]"
                            src={support}
                            alt="AMCO | Intermediários de Crédito"
                        />
                    </div>
                    <div className="col-span-1">
                        <h2 className="font-bold text-xl mb-4">Olá!</h2>
                        <p className="mb-4 font-semibold">Sabia que pode consultar o <span className="font-extrabold">estado do processo</span> no seu computador?</p>
                        <p className="text-sm">Instale a nossa aplicação e tenha o estado do processo AMCO disponível a qualquer momento!</p>
                    </div>
                </div>
                <div className="flex justify-end">
                    <button
                        className="bg-transparent text-[#004775] px-4 py-2 text-xs rounded hover:underline transition-underline mr-2"
                        onClick={handleClose}
                    >
                        Instalar mais tarde
                    </button>
                    <button
                        className="flex items-center justify-center bg-[#004775] hover:bg-[#004775]/75 text-white font-bold py-2 px-4 rounded"
                        onClick={handleInstallClick}
                    >
                        Instalar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PwaInstallPrompt;
