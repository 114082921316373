import logoBP from "../../assets/bp-logo.svg";

const BancoPortugalInfo = () => (
    <div className="flex justify-end items-center">
        <div className="grid grid-flow-col gap-3 md:p-2 sm:p-0rounded-full align-middle">
            <p className="text-xs col-span-4 self-center md:text-right sm:text-left">
                © 2024 AMCO Intermediários de Crédito, Lda.
                <a href="https://www.bportugal.pt/intermediariocreditofar/amco-intermediarios-de-credito-lda" target="_blank" rel="noopener"> Intermediário de Crédito vinculado e <strong>autorizado pelo Banco de Portugal</strong> nº759.</a>
            </p>
            <img
                className="h-12 w-auto logo-bportugal col-span-1"
                src={logoBP}
                alt="Banco de Portugal"
            />
        </div>
    </div>
);

export default BancoPortugalInfo;