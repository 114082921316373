import BancoPortugalInfo from '../Common/BancoPortugalInfo';

const CopyrightInfo = () => (
    <>
        <div className="col-span-1 self-center">
            <ul className="md:flex sm:block">
                <li className="text-xs font-bold grow"><a href="https://www.bportugal.pt/intermediariocreditofar/amco-intermediarios-de-credito-lda" alt="Intermediação de Crédito" target="_blank">Intermediação de Crédito</a></li>
                <li className="text-xs font-bold grow"><a href="https://amco.pt/termos-e-condicoes/" alt="Termos e Condições" target="_blank">Termos e Condições</a></li>
                <li className="text-xs font-bold grow"><a href="https://amco.pt/politica-de-privacidade/" alt="Política de Privacidade" target="_blank">Política de Privacidade</a></li>
            </ul>
        </div>
        <div className="col-span-1 self-center mobile-bp">
            <BancoPortugalInfo />
        </div>
    </>
);

export default CopyrightInfo;