import React from 'react';
import graphic from "../assets/404.gif";
import logo from "../assets/amco-logo-gold.svg";

const NotFoundPage = () => {
  return (
    <div className="min-h-full pt-16 pb-12 flex flex-col bg-white">
      <main className="flex-grow flex flex-col justify-center max-w-full w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 flex justify-center">
          <a href="/dashboard" className="inline-flex">
            <span className="sr-only">ERRO 404</span>
            <img
              className="h-16 w-auto"
              src={logo}
              alt="AMCO Intermediários de Crédito"
            />
          </a>
        </div>
        <div className="">
          <div className="text-center">
            <img
              className="w-[auto] md:h-[500px] mx-auto flex justify-center"
              src={graphic}
              alt=""
            />
            <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Página não encontrada.</h1>
            <p className="mt-2 text-base text-gray-500">Lamentamos, mas a página que procura não existe.</p>
            <div className="mt-6">
              <a href="/dashboard" className="text-base font-medium text-[#004774] hover:text-[#004774]">
                Ir para o Estado do Processo<span aria-hidden="true"> &rarr;</span>
              </a>
            </div>
          </div>
        </div>
      </main>
      <footer className="flex-shrink-0 max-w-full w-full mx-auto px-4 sm:px-6 lg:px-8 mt-2">

      </footer>
    </div>
  )
}

export default NotFoundPage;