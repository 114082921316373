import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { PiggyBank, CoinsIcon, CarIcon, Home } from "lucide-react";
import { MdInfoOutline, MdOutlineDocumentScanner } from "react-icons/md";

const ProcessCard = ({ data }) => {
    const navigate = useNavigate();
    const handlePreApprovalClick = () => {
        const authToken = localStorage.getItem('accessToken');
        if (authToken) {
            navigate(`/lead?token=${authToken}`);
        } else {
            console.error('No authToken found in localStorage.');
            navigate('/redirect');
        }
    };

    const handleDocumentSubmitClick = () => {
        const authToken = localStorage.getItem('accessToken');
        const processId = localStorage.getItem('processId');
        if (authToken && processId) {
            navigate(`/documentos?token=${authToken}`);
        } else {
            console.error('No authToken or processId found in localStorage.');
            navigate('/redirect');
        }
    };

    if (!data) { return <p>Sem processos a apresentar.</p>; }

    return (
        <div className="mt-4 grid md:grid-cols-12 md:gap-4 sm:grid-cols-1">
            <div key={data.id} className="bg-white col-span-12 p-4 rounded-lg align-middle shadow-md">
                <div className="grid md:grid-cols-5 sm:grid-cols-1 gap-0 mb-8">
                    <div className="flex items-center justify-start mobile-creditType">

                        {data.creditType == 1 ? (
                            <PiggyBank className="w-12 h-12 px-2 mr-2 rounded bg-[#FFC107] text-white" />
                        ) : data.creditType == 2 ? (
                            <CoinsIcon className="w-12 h-12 px-2 mr-2 rounded bg-[#004775] text-white" />
                        ) : data.creditType == 3 ? (
                            <CarIcon className="w-12 h-12 px-2 mr-2 rounded bg-[#FF5722] text-white" />
                        ) : data.creditType == 4 ? (
                            <Home className="w-12 h-12 px-2 mr-2 rounded bg-[#4CAF50] text-white" />
                        ) : (
                            ""
                        )}

                        <p className="col-span-2 text-gray-700 text-sm font-medium ">
                            <span className="uppercase text-xs">Tipo de Crédito</span><br />
                            <span className="text-gray-700 text-xs font-semibold">{(data.creditTypeName)}</span>
                        </p>
                    </div>

                    <div className="flex col-span-1 items-center md:justify-center sm:justify-start mobile-spacing">
                        <p className="col-span-2 text-gray-700 text-sm font-medium">
                            <span className="uppercase text-xs">Montante</span><br />
                            <span className="text-gray-700 text-xs font-semibold">
                                {(data.amount)}€
                            </span>
                        </p>
                    </div>

                    <div className="flex col-span-1 items-center md:justify-center sm:justify-start mobile-spacing">
                        <p className="col-span-2 text-gray-700 text-sm font-medium">
                            <span className="uppercase text-xs">Prazo</span><br />
                            <span className="text-gray-700 text-xs font-semibold">
                                {(data.deadline)} meses
                            </span>
                        </p>
                    </div>

                    <div className="flex col-span-1 items-center md:justify-center sm:justify-start mobile-spacing">
                        <p className="col-span-4 text-gray-700 text-sm font-medium">
                            <span className="uppercase text-xs">Estado do Processo</span><br />
                            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-semibold bg-yellow-100 text-yellow-800">
                                {data.state === 2 && (<>Novo - Sem Pré-Aprovação</>)}
                                {data.state === 3 && (<>Aguarda Documentos</>)}
                                {data.state >= 4 && (<>Em Análise</>)}
                            </span>
                        </p>
                    </div>

                    <div className="flex col-span-1 items-center md:justify-center sm:justify-start mobile-spacing">
                        {data.state !== 2 && data.startRenderAux !== null && (
                            <p className="text-gray-700 text-sm font-medium">
                                <span className="uppercase text-xs">Prestação Mensal</span><br />
                                <span className="text-gray-700 text-xs font-semibold">{data.startRenderAux}€*</span>
                            </p>
                        )}
                    </div>
                </div>

                {data.state === 2 && (
                    <button
                        type="button"
                        className="flex items-center justify-center amco-blue hover:amco-blue/75 text-white font-bold py-2 px-4 rounded w-full"
                        onClick={() => handlePreApprovalClick(data.leadToken)}
                    >
                        Obter Pré-Aprovação <MdInfoOutline className="ml-2 text-white" />
                    </button>
                )}

                {data.state === 3 && (
                    <button
                        type="button"
                        className="flex items-center justify-center bg-[#E60022] hover:amco-blue/75 text-white font-bold py-2 px-4 rounded w-full"
                        onClick={() => handleDocumentSubmitClick(data.leadToken)}
                    >
                        Enviar Documentação Necessária <MdOutlineDocumentScanner className="ml-2 text-white" />
                    </button>
                )}

                {data.state === 8 && (
                    <button
                        type="button"
                        className="flex items-center justify-center bg-[#E60022] hover:amco-blue/75 text-white font-bold py-2 px-4 rounded w-full"
                        onClick={() => handleDocumentSubmitClick(data.leadToken)}
                    >
                        Enviar Documentação Necessária <MdOutlineDocumentScanner className="ml-2 text-white" />
                    </button>
                )}

                {data.state === 9 && (
                    <button
                        type="button"
                        className="flex items-center justify-center bg-[#E60022] hover:amco-blue/75 text-white font-bold py-2 px-4 rounded w-full"
                        onClick={() => handleDocumentSubmitClick(data.leadToken)}
                    >
                        Enviar Documentação Necessária <MdOutlineDocumentScanner className="ml-2 text-white" />
                    </button>
                )}

                {data.state === 4 && (
                    <button
                        disabled
                        type="button"
                        className="flex items-center justify-center amco-blue hover:amco-blue/75 text-white font-bold py-2 px-4 rounded w-full"
                    >
                        O Seu Processo Está A Ser Analisado
                    </button>
                )}

                {data.state !== 2 && data.startRenderAux !== null && (
                    <span className="mt-4 flex items-center justify-center text-xs">
                        * Prestação mensal indicativa, sem inclusão de seguros, taxas e/ou despesas eventualmente aplicáveis.
                    </span>
                )}
            </div>
        </div>
    );
};

export default ProcessCard;