import { MdWhatsapp, MdLocalPhone, MdOutlineEmail } from "react-icons/md";

const Contacts = () => (
    <div className="grid grid-cols-3 gap-4 mt-5 text-sm font-bold">
        <div className="whatsapp-color">
            <a href="https://wa.me/351912608364?text=" alt="" target="_blank" className="inline-flex items-center">
                <MdWhatsapp />
                <span className="ml-2">WhatAapp</span>
            </a>
        </div>
        <div className="">
            <a href="tel:+351912608364" alt="" className="inline-flex items-center">
                <MdLocalPhone />
                <span className="ml-2">912 608 364</span>
            </a>
        </div>
        <div className="">
            <a href="mailto:geral@amco.pt" alt="Enviar um email para AMCO" className="inline-flex items-center">
                <MdOutlineEmail />
                <span className="ml-2">geral@amco.pt</span>
            </a>
        </div>
    </div>

);

export default Contacts;