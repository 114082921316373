import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { RadioGroup } from '@headlessui/react';
import { MdOutlineLock, MdOutlinePersonAddAlt, MdOutlineExpandMore, MdCheckCircle } from "react-icons/md";

const StepTwo = ({ form2Data, handle2InputChange, handle2DateChange, formErrors, allDataOptions, isChecked, setIsChecked, setSelectedOption, selectedOption }) => {
    return (
        <div className="space-y-4">
            <h2 className="text-xl font-semibold text-gray-700">Segundo Titular</h2>

            <hr className="mt-2" />
            <div className="grid md:grid-cols-3 sm:grid-cols-2 gap-4 mt-4">
                <div className="form-group">
                    <label htmlFor="relations" className='mb-[10px] block text-sm font-medium text-dark'>
                        Relação com o 1º Titular
                    </label>
                    <div className='relative z-20'>
                        <select
                            name="relations"
                            id="relations"
                            className='text-sm relative z-20 w-full rounded-lg border border-stroke dark:border-dark-3 bg-white py-[10px] px-5 text-dark outline-none transition focus:bg-amco-gold-simple active:bg-amco-gold-simple disabled:cursor-default disabled:bg-gray-2'
                            value={form2Data.relations}
                            onChange={handle2InputChange}
                        >
                            <option value="" disabled selected>Escolha a sua relação</option>
                            {allDataOptions.relations && allDataOptions.relations.map((relationsOption) => (
                                <option key={relationsOption.id} value={relationsOption.id} className='dark:bg-dark-2'>
                                    {relationsOption.name}
                                </option>
                            ))}
                        </select>
                        <span className='absolute right-4 top-1/2 z-10 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2 border-body-color'></span>
                    </div>
                    {formErrors['relations'] && <p className="pt-1 text-xs text-red-500">{formErrors['relations']}</p>}
                </div>
            </div>
            <div className="grid md:grid-cols-3 sm:grid-cols-2 gap-4 mt-4">
                <div className="form-group col-span-2">
                    <label htmlFor="name" className='mb-[10px] block text-sm font-medium text-dark'>
                        Nome Completo
                    </label>
                    <input
                        name="name" id="name"
                        onChange={handle2InputChange}
                        value={form2Data.name}
                        type='text'
                        placeholder='Introduza o seu nome completo'
                        className='text-sm w-full bg-white rounded-md border border-stroke dark:border-dark-3 py-[10px] px-5 text-dark outline-none transition focus:bg-amco-gold-simple active:bg-amco-gold-simple disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
                    />
                    {formErrors['name'] && <p className="pt-1 text-xs text-red-500">{formErrors['name']}</p>}
                </div>

                <div className="form-group">
                    <label htmlFor="birthDate" className='mb-[10px] block text-sm font-medium text-dark'>
                        Data De Nascimento
                    </label>
                    <input
                        name="birthDate" id="birthDate"
                        onChange={handle2InputChange}
                        value={form2Data.birthDate ? form2Data.birthDate.split('T')[0] : ''}
                        type='date'
                        className='text-sm w-full bg-white rounded-md border border-stroke dark:border-dark-3 py-[10px] px-5 text-dark outline-none transition focus:bg-amco-gold-simple active:bg-amco-gold-simple disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
                    />
                    {formErrors['birthDate'] && <p className="pt-1 text-xs text-red-500">{formErrors['birthDate']}</p>}
                </div>
            </div>


            <div className="grid md:grid-cols-3 sm:grid-cols-2 gap-4 mt-4">
                <div className="form-group">
                    <div className="anchor" id="gender"></div>
                    <label className='mb-[10px] block text-sm font-medium text-dark' htmlFor="gender">
                        Género
                    </label>
                    <div className='relative z-20'>
                        <select
                            name="gender"
                            id="gender"
                            className='text-sm relative z-20 w-full   rounded-lg border border-stroke dark:border-dark-3 bg-white py-[10px] px-5 text-dark outline-none transition focus:bg-amco-gold-simple active:bg-amco-gold-simple disabled:cursor-default disabled:bg-gray-2'
                            value={form2Data.gender}
                            onChange={handle2InputChange}
                        >
                            <option value="" disabled selected>Escolha o seu género</option>
                            {allDataOptions.gender && allDataOptions.gender.map((genderOption) => (
                                <option key={genderOption.id} value={genderOption.id} className='dark:bg-dark-2'>
                                    {genderOption.gender}
                                </option>
                            ))}
                        </select>
                        <span className='absolute right-4 top-1/2 z-10 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2 border-body-color'></span>
                    </div>
                    {formErrors['gender'] && <p className="pt-1 text-xs text-red-500">{formErrors['gender']}</p>}
                </div>

                <div className="form-group">
                    <div className="anchor" id="maritalAnchor"></div>
                    <label className='mb-[10px] block text-sm font-medium text-dark' htmlFor="civilStatus">
                        Estado Civil
                    </label>
                    <div className='relative z-20'>
                        <select
                            name="civilStatus"
                            id="civilStatus"
                            className='text-sm relative z-20 w-full   rounded-lg border border-stroke dark:border-dark-3 bg-white py-[10px] px-5 text-dark outline-none transition focus:bg-amco-gold-simple active:bg-amco-gold-simple disabled:cursor-default disabled:bg-gray-2'
                            value={form2Data.civilStatus}
                            onChange={handle2InputChange}
                        >
                            <option value="" disabled selected>Qual o seu estado civil?</option>
                            {allDataOptions.maritalStatus && allDataOptions.maritalStatus.map((maritalOption) => (
                                <option key={maritalOption.id} value={maritalOption.id} className='dark:bg-dark-2'>
                                    {maritalOption.name}
                                </option>
                            ))}
                        </select>
                        <span className='absolute right-4 top-1/2 z-10 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2 border-body-color text-dark'>
                            <MdOutlineExpandMore />
                        </span>
                    </div>
                    {formErrors['civilStatus'] && <p className="pt-1 text-xs text-red-500">{formErrors['civilStatus']}</p>}
                </div>

                <div className="form-group">
                    <label htmlFor="nif" className='mb-[10px] block text-sm font-medium text-dark'>
                        NIF
                    </label>
                    <input
                        name="nif" id="nif"
                        type='text'
                        value={form2Data.nif}
                        onChange={handle2InputChange}
                        placeholder='O seu número contribuinte'
                        className='text-sm w-full bg-white rounded-md border border-stroke dark:border-dark-3 py-[10px] px-5 text-dark outline-none transition focus:bg-amco-gold-simple active:bg-amco-gold-simple disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
                    />
                    {formErrors['nif'] && <p className="pt-1 text-xs text-red-500">{formErrors['nif']}</p>}
                </div>
            </div>


            <div className="grid md:grid-cols-3 sm:grid-cols-2 gap-4 mt-4">
                <div className="form-group">
                    <label htmlFor="phone" className='mb-[10px] block text-sm font-medium text-dark'>
                        Telemóvel
                    </label>
                    <input
                        name="phone" id="phone"
                        onChange={handle2InputChange}
                        value={form2Data.phone}
                        type='text'
                        placeholder='Introduza o seu número de telemóvel'
                        className='text-sm w-full bg-white rounded-md border border-stroke dark:border-dark-3 py-[10px] px-5 text-dark outline-none transition focus:bg-amco-gold-simple active:bg-amco-gold-simple disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
                    />
                    {formErrors['phone'] && <p className="pt-1 text-xs text-red-500">{formErrors['phone']}</p>}
                </div>
                <div className="form-group col-span-2">
                    <label htmlFor="email" className='mb-[10px] block text-sm font-medium text-dark'>
                        Endereço de Email
                    </label>
                    <input
                        name="email" id="email"
                        onChange={handle2InputChange}
                        value={form2Data.email}
                        type='email'
                        placeholder='Introduza o seu endereço de email'
                        className='text-sm w-full bg-white rounded-md border border-stroke dark:border-dark-3 py-[10px] px-5 text-dark outline-none transition focus:bg-amco-gold-simple active:bg-amco-gold-simple disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
                    />
                    {formErrors['email'] && <p className="pt-1 text-xs text-red-500">{formErrors['email']}</p>}
                </div>
            </div>


            <div className="grid md:grid-cols-3 sm:grid-cols-2 gap-4 mt-4">
                <div className="form-group">
                    <div className="anchor" id="nationality"></div>
                    <label className='mb-[10px] block text-sm font-medium text-dark' htmlFor="nationality">
                        Nacionalidade
                    </label>
                    <div className='relative z-20'>
                        <select
                            name="nationality"
                            id="nationality"
                            className='text-sm relative z-20 w-full   rounded-lg border border-stroke dark:border-dark-3 bg-white py-[10px] px-5 text-dark outline-none transition focus:bg-amco-gold-simple active:bg-amco-gold-simple disabled:cursor-default disabled:bg-gray-2'
                            value={form2Data.country}
                            onChange={handle2InputChange}
                        >
                            <option value="" disabled selected>Escolha a sua nacionalidade</option>
                            {allDataOptions.nationality && allDataOptions.nationality.map((nationalityOption) => (
                                <option key={nationalityOption.id} value={nationalityOption.id} className='dark:bg-dark-2'>
                                    {nationalityOption.name}
                                </option>
                            ))}

                        </select>
                        <span className='absolute right-4 top-1/2 z-10 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2 border-body-color'></span>
                    </div>
                    {formErrors['nationality'] && <p className="pt-1 text-xs text-red-500">{formErrors['nationality']}</p>}
                </div>
            </div>


            <h2 className="text-xl font-semibold text-gray-700 mt-10">Emprego</h2>
            <hr className="mt-2" />
            <div className="grid md:grid-cols-3 sm:grid-cols-2 gap-4 mt-4">
                <div className="form-group">
                    <div className="anchor" id="employerTypeAnchor"></div>
                    <label className='mb-[10px] block text-sm font-medium text-dark' htmlFor="secondHolderEmployerType">
                        Entidade Empregadora
                    </label>
                    <div className='relative z-20'>
                        <select
                            name="employerType"
                            id="employerType"
                            className='text-sm relative z-20 w-full   rounded-lg border border-stroke dark:border-dark-3 bg-white py-[10px] px-5 text-dark outline-none transition focus:bg-amco-gold-simple active:bg-amco-gold-simple disabled:cursor-default disabled:bg-gray-2'
                            value={form2Data.employerType}
                            onChange={handle2InputChange}
                        >
                            <option value="" disabled selected>Escolha o tipo de empregador</option>
                            {allDataOptions.employerType && allDataOptions.employerType.map((employerOption) => (
                                <option key={employerOption.id} value={employerOption.id} className='dark:bg-dark-2'>
                                    {employerOption.name}
                                </option>
                            ))}
                        </select>
                        <span className='absolute right-4 top-1/2 z-10 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2 border-body-color'></span>
                    </div>
                    {formErrors['employerType'] && <p className="pt-1 text-xs text-red-500">{formErrors['employerType']}</p>}
                </div>

                <div className="form-group">
                    <div className="anchor" id="contractTypeAnchor"></div>
                    <label className='mb-[10px] block text-sm font-medium text-dark' htmlFor="secondHolderContractType">
                        Situação Profissional
                    </label>
                    <div className='relative z-20'>
                        <select
                            name="contractType"
                            id="contractType"
                            className='text-sm relative z-20 w-full   rounded-lg border border-stroke dark:border-dark-3 bg-white py-[10px] px-5 text-dark outline-none transition focus:bg-amco-gold-simple active:bg-amco-gold-simple disabled:cursor-default disabled:bg-gray-2'
                            value={form2Data.contractType}
                            onChange={handle2InputChange}
                        >
                            <option value="" disabled selected>Qual a sua situação profissional?</option>
                            {allDataOptions.contractType && allDataOptions.contractType.map((contractOption) => (
                                <option key={contractOption.id} value={contractOption.id} className='dark:bg-dark-2'>
                                    {contractOption.name}
                                </option>
                            ))}
                        </select>
                        <span className='absolute right-4 top-1/2 z-10 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2 border-body-color'></span>
                    </div>
                    {formErrors['contractType'] && <p className="pt-1 text-xs text-red-500">{formErrors['contractType']}</p>}
                </div>

                <div className="form-group">
                    <label htmlFor="sinceCompany" className='mb-[10px] block text-sm font-medium text-dark'>
                        Desde que data (mês/ano)
                    </label>
                    <input
                        name="sinceCompany" id="sinceCompany"
                        type='date'
                        value={form2Data.sinceCompany ? form2Data.sinceCompany.split('T')[0] : ''}
                        onChange={handle2InputChange}
                        className='text-sm w-full bg-white rounded-md border border-stroke dark:border-dark-3 py-[10px] px-5 text-dark outline-none transition focus:bg-amco-gold-simple active:bg-amco-gold-simple disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
                    />
                    {formErrors['sinceCompany'] && <p className="pt-1 text-xs text-red-500">{formErrors['sinceCompany']}</p>}
                </div>

                <div className="form-group">
                    <label htmlFor="salary" className='mb-[10px] block text-sm font-medium text-dark'>
                        Vencimento Mensal
                    </label>
                    <div className="relative mt-2 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <span className="text-gray-500 sm:text-sm">€ </span>
                        </div>
                        <input
                            type="text"
                            name="salary"
                            id="salary"
                            onChange={handle2InputChange}
                            className="text-sm pl-7 w-full bg-white rounded-md border border-stroke dark:border-dark-3 py-[10px] px-5 text-dark outline-none transition focus:bg-amco-gold-simple active:bg-amco-gold-simple disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2"
                            placeholder="0.00"
                            aria-describedby="salary"
                            value={form2Data.salary}
                        />
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <span className="text-gray-500 sm:text-sm" id="salary">
                                EUR
                            </span>
                        </div>
                    </div>
                    {formErrors['salary'] && <p className="pt-1 text-xs text-red-500">{formErrors['salary']}</p>}
                </div>
            </div>
        </div>
    );
};

export default StepTwo;
