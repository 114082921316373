import axios from 'axios';
import { API_URL, X_Access_Token } from '../config/configs';

const setDefaultHeaders = () => {
    const accessToken = localStorage.getItem('accessToken');
    const input = localStorage.getItem('input');
    axios.defaults.headers.common['X-Access-Token'] = X_Access_Token;

    if (accessToken) {
        axios.defaults.headers.common['Authorization'] = accessToken;
        axios.defaults.headers.common['Auth-Token'] = accessToken;
    }

    if (input) {
        axios.defaults.headers.common['Auth-Input'] = input;
    }
};

const apiService = {
    // Get lead data from given leadtoken on the url
    fetchInitialData: async (leadToken) => {
        setDefaultHeaders();
        const response = await axios.get(`${API_URL}/leads/token?leadToken=${leadToken}`);
        return response.data;
    },
    // Get ProcessID based on input contact (phone/email) and leadtoken
    getProcessID: async () => {
        setDefaultHeaders();
        const response = await axios.get(`${API_URL}/getAllLeadData`);
        return response.data;
    },
    // Get API data to populate static information fields
    fetchAllDataOptions: async () => {
        setDefaultHeaders();
        const response = await axios.get(`${API_URL}/allData`);
        return response.data;
    },
    // Get Process information from process id
    fetchProcessDetails: async (processId) => {
        setDefaultHeaders();
        const response = await axios.get(`${API_URL}/process/${processId}`);
        return response.data;
    },
    // Update lead data with form infomations
    updateLeadData: async (processId, data) => {
        setDefaultHeaders();
        const response = await axios.put(`${API_URL}/processClients/${processId}`, data);
        return response.data;
    },
    // Get Process documents from process id
    fetchDocumentsByProcess: async (processId) => {
        setDefaultHeaders();
        const response = await axios.get(`${API_URL}/processDoc/${processId}`);
        return response.data;
    },
    // Updates leadStatus based on given status id
    updateLeadStatus: async (processId, newStatusId) => {
        setDefaultHeaders();
        const response = await axios.post(`${API_URL}/leads/updateStatus`, {
            processId,
            statusId: newStatusId
        });
        return response.data;
    },
    // Creates a second holder for the process
    createSecondHolder: async (processId, data) => {
        setDefaultHeaders();
        const response = await axios.post(`${API_URL}/processClients/${processId}`, data);
        return response.data;
    },
    // Validates the current process data with lead rules validation
    verifyRules: async (data) => {
        setDefaultHeaders();
        try {
            const response = await axios.post(`${API_URL}/verify-rules`, data);
            return response.data;
        } catch (error) {
            console.error('Error verifying rules:', error.response ? error.response.data : 'No response');
            throw error;
        }
    },
    // Upload document to the backoffice
    uploadDocument: async (processId, data) => {
        console.log(processId);
        console.log(data);
        setDefaultHeaders();
        try {
            const response = await axios.post(`${API_URL}/doc/upload-ac/${processId}`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error uploading document:', error.response ? error.response.data : 'No response');
            throw error;
        }
    },
    // Delete document on the backoffice
    deleteDocument: async (data) => {
        setDefaultHeaders();
        try {
            const response = await axios.post(`${API_URL}/doc/delete-ac`, data);
            return response.data;
        }
        catch (error) {
            console.error('Error deleting document:', error.response ? error.response.data : 'No response');
            throw error;
        }
    },
    // Trigger automatic document download
    getAutomaticDocs: async (data) => {
        setDefaultHeaders();
        try {
            const response = await axios.post(`${API_URL}/doc/getAutomaticDocs`, data);
            return response.data;
        } catch (error) {
            console.error('Error getting automatic documents:', error.response ? error.response.data : 'No response');
            throw error;
        }
    }
};

export default apiService;
