function ProgressBar({ currentStep, totalSteps }) {
  const progress = (currentStep / totalSteps) * 100;
  const progressValue = Math.round(progress);

  return (
    <div className='bg-stroke bg-amco relative h-4 w-full rounded-2xl'>
      <div
        style={{ width: `${progressValue}%` }}
        className="amco-blue absolute top-0 left-0 flex h-full items-center justify-center rounded-2xl text-xs font-semibold text-white">
        {progressValue}%
      </div>
    </div>
  );
}

export default ProgressBar;