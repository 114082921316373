import React, { useState, useMemo, useEffect, useRef } from 'react';
import ProcessDocuments from '../Documents/ProcessDocuments';
import RedirectPage from '../RedirectPage';
import { useLocation } from 'react-router-dom';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export const DocumentsWrapper = () => {
    const query = useQuery();
    const token = query.get('token');
    if (token) {
        return <ProcessDocuments leadToken={token} />;
    } else {
        return <RedirectPage to="/login" />;
    }
};
